body {
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  background: #f0f0f0;
}
.frontal{
  height: 12rem;
  width: auto;
}


/* Animación de hojas de papel */
.floating-paper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
  overflow: hidden;
}

.paper {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  opacity: 0.7;
  animation: floatPaper linear infinite;
  pointer-events: none;
}

.paper::after {
  content: '';
  position: absolute;
  top: 10%;
  left: 15%;
  width: 70%;
  height: 3px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  box-shadow: 0 12px 0 rgba(0, 0, 0, 0.05), 0 24px 0 rgba(0, 0, 0, 0.05);
}

.paper:nth-child(1) {
  width: 60px;
  height: 80px;
  left: 10%;
  animation-duration: 15s;
  animation-delay: -2s;
}

.paper:nth-child(2) {
  width: 70px;
  height: 90px;
  left: 30%;
  animation-duration: 18s;
  animation-delay: -6s;
}

.paper:nth-child(3) {
  width: 50px;
  height: 70px;
  left: 50%;
  animation-duration: 13s;
  animation-delay: -9s;
}

.paper:nth-child(4) {
  width: 65px;
  height: 85px;
  left: 70%;
  animation-duration: 16s;
  animation-delay: -4s;
}

.paper:nth-child(5) {
  width: 55px;
  height: 75px;
  left: 90%;
  animation-duration: 14s;
  animation-delay: -7s;
}

.paper:nth-child(6) {
  width: 58px;
  height: 78px;
  left: 15%;
  animation-duration: 17s;
  animation-delay: -3s;
}

.paper:nth-child(7) {
  width: 62px;
  height: 82px;
  left: 45%;
  animation-duration: 19s;
  animation-delay: -8s;
}

.paper:nth-child(8) {
  width: 52px;
  height: 72px;
  left: 75%;
  animation-duration: 16s;
  animation-delay: -5s;
}

@keyframes floatPaper {
  0% {
    transform: translateY(-100%) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}

/* Aseguramos que el contenido esté por encima de las hojas */
.App {
  text-align: center;
  background: transparent;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.App::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: 0;
  background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(240,240,240,1) 100%);
  animation: rotate 30s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.header-logo {
  max-height: 300px;
  width: auto;
  margin-bottom: 1rem;
}

.title {
  font-size: 6rem;
  font-weight: bold;
  color: #000000;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.highlight {
  color: #0066ff;
  font-weight: bold;
}

.subtitle {
  font-size: 2rem;
  color: #333333;
  margin: 10px 0 30px 0;
  font-weight: 500;
}

.description {
  font-size: 1.2rem;
  color: #666666;
  max-width: 800px;
  line-height: 1.6;
  margin: 0;
  padding: 0 20px;
}

.button-container {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  justify-content: center;
}

.primary-button {
  padding: 12px 24px;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
  background: linear-gradient(135deg, #0066ff 0%, #0044cc 100%);
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

.primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 102, 255, 0.2);
}

@media screen and (max-width: 768px) {

  .frontal{
    height: 4.6rem;
    width: auto;
  }


  .button-container {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  .primary-button {
    width: 100%;
    max-width: 300px;
  }
}

@media screen and (max-width: 480px) {
  .button-container {
    margin-top: 30px;
  }
  .frontal{
    height: 3.6rem;
    width: auto;
  }

  .primary-button {
    max-width: 250px;
    font-size: 1rem;
    padding: 10px 20px;
  }
}

.info-section {
  padding: 80px 20px;
  position: relative;
  z-index: 1;
  background: linear-gradient(45deg, #0066ff, #0052cc);
  overflow: hidden;
}

.info-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%),
              linear-gradient(-45deg, rgba(255,255,255,0.1) 25%, transparent 25%),
              linear-gradient(45deg, transparent 75%, rgba(255,255,255,0.1) 75%),
              linear-gradient(-45deg, transparent 75%, rgba(255,255,255,0.1) 75%);
  background-size: 60px 60px;
  animation: moveBackground 20s linear infinite;
  z-index: 0;
}

@keyframes moveBackground {
  0% {
    background-position: 0 0, 30px 0, 30px -30px, 0px 30px;
  }
  100% {
    background-position: 60px 60px, 90px 60px, 90px 30px, 60px 90px;
  }
}

.section-title {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 50px;
  font-weight: bold;
  position: relative;
  z-index: 1;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.info-card {
  text-align: left;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  backdrop-filter: blur(5px);
}

.info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.info-subtitle {
  font-size: 1.5rem;
  color: #0066ff;
  margin-bottom: 20px;
  font-weight: 600;
}

.info-text {
  font-size: 1.1rem;
  color: #444444;
  line-height: 1.6;
  margin: 0;
}

.solutions-section,
.chatbot-section {
  position: relative;
  background: rgba(240, 240, 240, 0.92);
  overflow: hidden;
}

.solutions-section .floating-paper,
.chatbot-section .floating-paper {
  z-index: 0;
}

.solutions-section .section-title,
.solutions-grid,
.chatbot-content {
  position: relative;
  z-index: 1;
}

.solutions-section {
  padding: 80px 20px;
  background-color: #f8f9fa;
  position: relative;
  z-index: 1;
  background: rgba(240, 240, 240, 0.92) !important;
}

.solutions-section .section-title {
  color: #000000;
}

.solutions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.solution-card {
  background-color: white;
  border-radius: 12px;
  padding: 25px;
  text-align: center;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.solution-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.solution-image {
  width: 200px;
  height: 200px;
  margin: 0 auto 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
}

.solution-image img {
  width: 140%;
  height: 140%;
  object-fit: contain;
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.solution-card:hover .solution-image img {
  transform: scale(1.05);
}

.solution-subtitle {
  font-size: 1.3rem;
  color: #0066ff;
  margin: 0 0 12px 0;
  font-weight: 600;
}

.solution-text {
  font-size: 1rem;
  color: #444444;
  line-height: 1.5;
  margin: 0;
  max-width: 350px;
  margin: 0 auto;
}

.automation-section {
  padding: 80px 20px;
  background: linear-gradient(-45deg, #0066ff, #0052cc, #1a75ff, #0047b3);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

.automation-section::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.2), transparent 50%),
              radial-gradient(circle at 60% 40%, rgba(255, 255, 255, 0.2), transparent 40%);
  animation: rotateBG 20s linear infinite;
  z-index: 0;
}

@keyframes rotateBG {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.automation-section .floating-paper {
  display: none;
}

.automation-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  gap: 40px;
}

.automation-text {
  flex: 1;
  padding-right: 0;
  text-align: center;
  color: white;
}

.automation-text .section-title {
  margin-bottom: 40px;
  font-size: 2.5rem;
}

.automation-image {
  flex: 1;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.automation-image img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
}

@media screen and (max-width: 1024px) {
  .automation-content {
    gap: 30px;
  }

  .automation-image {
    max-width: 450px;
  }

  .automation-text .section-title {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 768px) {
  .automation-section {
    padding: 60px 20px;
  }

  .automation-content {
    flex-direction: column;
    gap: 40px;
  }

  .automation-text {
    padding: 0;
  }

  .automation-text .section-title {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .automation-image {
    max-width: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .automation-section {
    padding: 40px 15px;
  }

  .automation-text .section-title {
    font-size: 1.75rem;
    margin-bottom: 25px;
  }

  .automation-image {
    max-width: 320px;
  }
}

.feature-container {
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feature-subtitle {
  font-size: 2.2rem;
  font-weight: 600;
  margin: 0 0 15px 0;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
  text-align: center;
  background: linear-gradient(120deg, #ffffff 0%, #f0f0f0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}

.feature-text {
  font-size: 1.3rem;
  line-height: 1.5;
  margin: 0;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease 0.2s forwards;
  text-align: center;
  max-width: 400px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chatbot-section {
  padding: 80px 20px;
  background-color: #f8f9fa;
  position: relative;
  z-index: 1;
  background: rgba(240, 240, 240, 0.92) !important;
}

.chatbot-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 60px;
  flex-direction: row;
  padding-top: 40px;
}

.chatbot-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
}

.chatbot-text .section-title {
  color: #000000;
  text-align: center;
  margin-bottom: 50px;
  font-size: 2.5rem;
  width: 100%;
}

.chatbot-image {
  flex: 0 0 500px;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatbot-image img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  display: block;
}

@media screen and (max-width: 1024px) {
  .chatbot-content {
    gap: 40px;
  }

  .chatbot-image {
    flex: 0 0 400px;
    max-width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .chatbot-section {
    padding: 40px 20px;
  }

  .chatbot-content {
    flex-direction: column;
    gap: 30px;
    padding-top: 20px;
  }

  .chatbot-text {
    text-align: center;
    width: 100%;
  }

  .chatbot-text .section-title {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .chatbot-image {
    flex: none;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    padding-top: 0;
  }
}

@media screen and (max-width: 480px) {
  .chatbot-section {
    padding: 30px 15px;
  }

  .chatbot-text .section-title {
    font-size: 1.75rem;
    margin-bottom: 25px;
  }

  .chatbot-image {
    max-width: 280px;
  }
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  width: 100%;
  margin: 0 auto;
}

.feature-item {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 2.5rem;
  color: #0066ff;
  margin-bottom: 15px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-icon i {
  transition: transform 0.2s ease;
}

.feature-item:hover .feature-icon i {
  transform: scale(1.1);
}

.feature-title {
  font-size: 1.2rem;
  color: #333333;
  margin: 0 0 10px 0;
  font-weight: 600;
  width: 100%;
  overflow-wrap: break-word;
}

.feature-description {
  font-size: 0.95rem;
  color: #666666;
  line-height: 1.5;
  margin: 0;
  width: 100%;
  overflow-wrap: break-word;
}

.solutions-section .section-content,
.chatbot-section .chatbot-content {
  position: relative;
  z-index: 1;
}

.validation-section {
  padding: 0;
  background: linear-gradient(-45deg, #0066ff, #0052cc, #1a75ff, #0047b3);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  position: relative;
  overflow: hidden;
  z-index: 2;
  min-height: 500px;
  display: flex;
  flex-direction: column;
}

.validation-content {
  position: relative;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.validation-section .section-title {
  color: white;
  text-align: center;
  font-size: 2.5rem;
  padding: 60px 20px;
  margin: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.1);
}

.validation-features {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.validation-item {
  flex: 1;
  padding: 40px 25px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  overflow: hidden;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.validation-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.validation-subtitle {
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}

.validation-text {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  line-height: 1.6;
  max-width: 300px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .validation-section .section-title {
    font-size: 2.2rem;
    padding: 50px 20px;
  }

  .validation-subtitle {
    font-size: 1.6rem;
  }

  .validation-text {
    font-size: 0.95rem;
    max-width: 250px;
  }
}

@media screen and (max-width: 768px) {
  .validation-section {
    min-height: auto;
  }

  .validation-section .section-title {
    font-size: 2rem;
    padding: 40px 20px;
  }

  .validation-features {
    flex-direction: column;
  }

  .validation-item {
    padding: 40px 20px;
    border-left: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .validation-subtitle {
    font-size: 1.5rem;
    margin-bottom: 0.8rem;
  }

  .validation-text {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 480px) {
  .validation-section .section-title {
    font-size: 1.75rem;
    padding: 30px 15px;
  }

  .validation-item {
    padding: 30px 15px;
  }

  .validation-subtitle {
    font-size: 1.3rem;
  }

  .validation-text {
    font-size: 0.9rem;
  }
}

.digitalization-section {
  position: relative;
  padding: 120px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  overflow: hidden;
}

.digitalization-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.digitalization-content .section-title {
  color: #000000;
  margin-bottom: 60px;
  font-size: 2.5rem;
  font-weight: 700;
  position: relative;
}

.digitalization-content .section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: #0066ff;
  border-radius: 2px;
}

.digitalization-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-top: 50px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.digitalization-item {
  background: rgba(255, 255, 255, 0.95);
  padding: 40px 30px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.digitalization-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.12);
}

.digitalization-subtitle {
  font-size: 1.6rem;
  color: #0066ff;
  margin-bottom: 20px;
  font-weight: 600;
  position: relative;
}

.digitalization-text {
  font-size: 1.1rem;
  color: #444444;
  line-height: 1.6;
  margin: 0;
}

@media (max-width: 992px) {
  .digitalization-features {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .digitalization-features {
    grid-template-columns: 1fr;
  }
  
  .digitalization-content .section-title {
    font-size: 2rem;
  }
}

.biometric-section {
  position: relative;
  padding: 120px 0;
  background: #0a192f;
  overflow: hidden;
  color: white;
}

.matrix-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

.matrix-column {
  display: flex;
  flex-direction: column;
  animation: fall 20s linear infinite;
  opacity: 0.15;
}

.matrix-symbol {
  color: #0066ff;
  font-family: monospace;
  font-size: 1.2rem;
  line-height: 1;
  animation: glow 1.5s ease-in-out infinite alternate;
  text-shadow: 0 0 5px #0066ff;
}

.matrix-column:nth-child(odd) {
  animation-duration: 25s;
}

.matrix-column:nth-child(3n) {
  animation-duration: 30s;
}

.matrix-column:nth-child(3n+1) .matrix-symbol {
  color: #4d94ff;
}

.matrix-column:nth-child(3n+2) .matrix-symbol {
  color: #0052cc;
}

@keyframes fall {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes glow {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0.8;
  }
}

.biometric-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.biometric-content .section-title {
  color: white;
  margin-bottom: 60px;
  font-size: 2.5rem;
  font-weight: 700;
  position: relative;
  text-shadow: 0 0 10px rgba(0, 102, 255, 0.3);
}

.biometric-content .section-title::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: #0066ff;
  box-shadow: 0 0 10px rgba(0, 102, 255, 0.5);
  border-radius: 2px;
}

.biometric-features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-top: 50px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.biometric-item {
  background: rgba(255, 255, 255, 0.05);
  padding: 40px 30px;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 102, 255, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 102, 255, 0.1);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.biometric-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 102, 255, 0.2);
  border-color: rgba(0, 102, 255, 0.3);
  background: rgba(255, 255, 255, 0.08);
}

.biometric-subtitle {
  font-size: 1.6rem;
  color: #0066ff;
  margin-bottom: 20px;
  font-weight: 600;
  text-shadow: 0 0 5px rgba(0, 102, 255, 0.3);
}

.biometric-text {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;
  margin: 0;
}

@media (max-width: 992px) {
  .biometric-features {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .matrix-background {
    justify-content: space-between;
  }
  
  .matrix-column {
    margin: 0 5px;
  }
}

@media (max-width: 768px) {
  .biometric-features {
    grid-template-columns: 1fr;
  }
  
  .biometric-content .section-title {
    font-size: 2rem;
  }
  
  .matrix-symbol {
    font-size: 1rem;
  }
}

/*Formulario de Contacto*/

.contact-form-section {
  padding: 80px 20px;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  position: relative;
  overflow: hidden;
}

.contact-form-section .section-title {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  font-weight: 700;
  position: relative;
}

.contact-form-section .section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: #3498db;
  border-radius: 2px;
}

.contact-form-container {
  max-width: 600px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.contact-form {
  padding: 30px;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #34495e;
  font-size: 1rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  background-color: #fff;
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
}

.submit-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 30px auto 0;
  padding: 12px 0;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(52, 152, 219, 0.3);
}

/* Media queries para el formulario en dispositivos móviles */
@media screen and (max-width: 768px) {
  .contact-form {
    padding: 25px 20px;
  }
  
  .contact-form-section .section-title {
    font-size: 2rem;
  }
  
  .submit-button {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .contact-form {
    padding: 20px 15px;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px 12px;
    font-size: 0.95rem;
  }
  
  .form-group label {
    font-size: 0.95rem;
  }
  
  .contact-form-section .section-title {
    font-size: 1.75rem;
  }
}

/* Footer Styles */
.footer {
  background-color: #f8f9fa;
  padding: 3rem 0;
  text-align: center;
  margin-top: 4rem;
  position: relative;
  z-index: 1;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  position: relative;
  z-index: 2;
}

.footer-logo {
  height: 48px;
  object-fit: contain;
  transition: transform 0.2s ease;
}

.footer-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.footer-copyright {
  font-size: 0.875rem;
  color: #666;
  margin: 0;
}

.social-links {
  display: flex;
  gap: 1.5rem;
  margin-top: 1rem;
}

.social-link {
  color: #666;
  font-size: 1.25rem;
  transition: color 0.2s ease;
}

.social-link:hover {
  color: #333;
}

.footer-content a:hover .footer-logo {
  transform: scale(1.05);
}

/* Media Queries for Responsive Design */
@media screen and (max-width: 1024px) {
  .title {
    font-size: 5rem;
  }
  
  .automation-content {
    flex-direction: column;
    text-align: center;
  }
  
  .automation-text {
    width: 100%;
    margin-bottom: 30px;
  }
  
  .automation-image {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .validation-content {
    flex-direction: column;
  }
  
  .validation-text {
    width: 100%;
    margin-bottom: 30px;
  }
  
  .validation-items {
    width: 100%;
  }
  
  .biometric-content {
    flex-direction: column;
  }
  
  .biometric-text {
    width: 100%;
    margin-bottom: 30px;
  }
  
  .biometric-grid {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .header-logo {
    max-height: 200px;
  }

  .title {
    font-size: 4rem;
  }

  .subtitle {
    font-size: 1.5rem;
    padding: 0 20px;
  }

  .description {
    font-size: 1rem;
    padding: 0 20px;
  }

  .button-container {
    flex-direction: column;
    gap: 15px;
    padding: 0 20px;
  }

  .primary-button, .secondary-button {
    width: 100%;
    text-align: center;
  }

  .info-section,
  .features-section,
  .solutions-section,
  .digitalization-section,
  .automation-section,
  .validation-section,
  .biometric-section {
    padding: 60px 20px;
  }

  .section-title {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .info-grid,
  .features-grid,
  .solutions-grid,
  .digitalization-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .solution-image {
    width: 150px;
    height: 150px;
  }

  .solution-image img {
    width: 120%;
    height: 120%;
  }

  .digitalization-item {
    padding: 30px 20px;
  }

  .chatbot-content {
    flex-direction: column;
    text-align: center;
  }

  .chatbot-image {
    flex: none;
    max-width: 100%;
    margin-bottom: 30px;
    order: 2;
  }
  
  .chatbot-text {
    order: 1;
    margin-bottom: 20px;
  }

  .feature-item {
    padding: 20px 15px;
  }
  
  .validation-items {
    flex-direction: column;
  }
  
  .validation-item {
    margin-bottom: 20px;
  }
  
  .footer-content {
    flex-direction: column;
    text-align: center;
  }
  
  .footer-logo-container {
    margin-bottom: 20px;
  }
  
  .footer-links {
    flex-direction: column;
    gap: 15px;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .header-logo {
    max-height: 150px;
  }

  .title {
    font-size: 3rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .section-title {
    font-size: 1.75rem;
  }

  .info-subtitle,
  .solution-subtitle,
  .feature-title {
    font-size: 1.2rem;
  }

  .info-text,
  .solution-text,
  .feature-description {
    font-size: 0.9rem;
  }

  .primary-button, .secondary-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .solution-image {
    width: 120px;
    height: 120px;
  }

  .solution-image img {
    width: 110%;
    height: 110%;
  }
  
  .info-section,
  .features-section,
  .solutions-section,
  .digitalization-section,
  .automation-section,
  .validation-section,
  .biometric-section {
    padding: 40px 15px;
  }
  
  .info-card,
  .solution-card,
  .feature-item,
  .digitalization-item,
  .validation-item,
  .biometric-item {
    padding: 20px 15px;
  }
  
  .feature-icon {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
  }
}

/* Ajustes adicionales para dispositivos muy pequeños */
@media screen and (max-width: 360px) {
  .header-logo {
    max-height: 120px;
  }

  .title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .description {
    font-size: 0.85rem;
  }

  .section-title {
    font-size: 1.5rem;
  }
  
  .solution-image {
    width: 100px;
    height: 100px;
  }
}

.scroll-button {
  background: transparent;
  border: 2px solid #0066ff;
  color: #0066ff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 40px;
  transition: all 0.3s ease;
  animation: bounce 2s infinite;
}

.scroll-button:hover {
  background: #0066ff;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 102, 255, 0.2);
  animation: none;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media screen and (max-width: 768px) {
  .scroll-button {
    width: 40px;
    height: 40px;
    margin-top: 30px;
  }
}

/* Estilos para el menú de navegación responsive */
.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px 0;
  backdrop-filter: blur(10px);
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.nav-logo {
  display: flex;
  align-items: center;
}

.nav-logo-img {
  height: 40px;
  width: auto;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #0066ff;
}

.nav-button {
  background-color: #0066ff;
  color: white !important;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: #0052cc;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 102, 255, 0.2);
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
}

.menu-toggle span {
  display: block;
  height: 3px;
  width: 100%;
  background-color: #333;
  border-radius: 3px;
  transition: all 0.3s ease;
}

/* Ajuste para el header con el menú fijo */
.App-header {
  padding-top: 80px;
}

/* Media queries para el menú responsive */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .nav-links {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.98);
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-150%);
    transition: transform 0.3s ease;
    backdrop-filter: blur(10px);
  }

  .nav-links.active {
    transform: translateY(0);
  }

  .nav-links li {
    margin: 15px 0;
  }

  .menu-toggle.active span:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }

  .menu-toggle.active span:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.active span:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }
}

@media screen and (max-width: 480px) {
  .nav-logo-img {
    height: 30px;
  }
  
  .nav-links {
    top: 50px;
  }
}
